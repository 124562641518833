<template>
  <v-dialog v-model="show" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-alert v-model="hasError" class="warning" dismissible>
        <text-center>{{ errorMessage }}</text-center>
      </v-alert>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" sm="12">
                <div v-if="isEdit === true">
                  <label>Empresa</label>
                  <h3>{{ creditToSave.razaoSocial }} - {{ creditToSave.cnpjRaiz.substring(0, 8) }}</h3>
                </div>
                <v-autocomplete
                  v-if="isEdit === false"
                  v-model="creditToSave.empresa"
                  label="Empresa*"
                  id="IdACEmpresa"
                  append-icon="mdi-chevron-down"
                  :items="userEstablishment"
                  item-text="details.socialName"
                  item-value="details"
                  :multiple="false"
                  hide-details
                  outlined
                  persistent-placeholder
                  :placeholder="obrigatorio"
                  :rules="[rules.empresaRules]"
                  @change="changeInput(creditToSave, 'empresa')"
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item
                      :id="item.details.cnpj"
                      #default="{ active }"
                      v-bind="attrs"
                      :class="{ 'pl-10': item.group }"
                      v-on="on"
                    >
                      <v-list-content :id="item.details.cnpj ? item.details.cnpj : item.socialName + 'content'">
                        <v-list-item-title
                          >{{ item.socialName }} ({{ item.details.cnpj.substring(0, 8) | cnpj }})</v-list-item-title
                        >
                      </v-list-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12">
                <v-currency-field
                  v-model="creditToSave.valor"
                  label="Crédito identificado*"
                  id="IdCredIden"
                  :error-messages="lowerExpectationMessage"
                  :rules="[rules.isValidValor, rules.isSmallerExpectValidValue, rules.isSmallerExpectValidExpect]"
                  outlined
                  persistent-placeholder
                  :placeholder="obrigatorio"
                  @input="changeInput(creditToSave, 'valor')"
                  @change="validatesTotal(creditToSave.valor)"
                  @keyup="validatesTotal(creditToSave.valor)"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-currency-field
                  v-model="creditToSave.expectativaTotalCredito"
                  id="idExpCred"
                  label="Expectativa total de crédito*"
                  :error-messages="messageEpectationGreaterThanValue"
                  :rules="[rules.isValidValor, rules.isSmallerExpectValidExpect]"
                  outlined
                  persistent-placeholder
                  :placeholder="obrigatorio"
                  @input="changeInput(creditToSave, 'expectativaTotalCredito')"
                  @change="validatesExpectation(creditToSave.expectativaTotalCredito)"
                  @keyup="validatesExpectation(creditToSave.expectativaTotalCredito)"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  maxlength="8"
                  type="date"
                  max="2100-12-31"
                  v-model="creditToSave.dataPrescricao"
                  id="idPresImi"
                  label="Prescrição iminente*​"
                  :rules="[rules.dateInvalid, rules.prescicaoValidDateRules, rules.creditRecordExistsDate]"
                  outlined
                  persistent-placeholder
                  :placeholder="'dd/MM/aaaa'"
                  @input="changeInput(creditToSave, 'dataPrescricao')"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-currency-field
                  v-model="creditToSave.valorPrescricao"
                  id="idCredPresc"
                  label="Valor do crédito a prescrever*"
                  :rules="[rules.isValidValor]"
                  outlined
                  persistent-placeholder
                  :placeholder="obrigatorio"
                  @input="changeInput(creditToSave, 'valorPrescricao')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <div class="text-left ml-8" style="font-size: 14px; color: #575767; font-weight: 400">
        * Campos de preenchimento obrigatório
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close" color="success" id="btnCancTot"> Cancelar </v-btn>
        <v-btn :disabled="!valid" color="success" @click="salvar" id="btnSaveTot"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import AdminServices from '@/services/adminServices.js';
import sortBy from 'lodash/sortBy';
import { mapGetters } from 'vuex';
import TotalizadorService from '@/services/totalizadorService.js';
import * as moment from 'moment';
import Vue from 'vue';
import VCurrencyField from 'v-currency-field';

import { eventBus } from '../../../../main.js';

Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

export default {
  name: 'ModalTotalizadores',

  props: ['creditoOrigin', 'totalizadores'],

  data() {
    return {
      valid: true,
      show: false,
      loadingSave: false,
      obrigatorio: 'Obrigatório',
      userEstablishment: [],
      title: '',
      isEdit: false,
      empresa: '',
      errorMessage: '',
      hasError: false,
      rate: 0,
      errors: {},
      noValid: true,
      totalizadorSave: {},
      lowerExpectationMessage: '',
      messageEpectationGreaterThanValue: '',
      rules: {
        isValidValor: (value) => this.isValidValorOrNot(value) || 'Preencha um valor acima de zero.',
        passwordAlreadyUsed: () => true || this.passwordAlreadyUsedMessage,
        isSmallerExpectValidValue: () => true || this.lowerExpectationMessage,
        isSmallerExpectValidExpect: () => true || this.messageEpectationGreaterThanValue,
        empresaRules: (value) => !!value || 'Selecione uma empresa.',
        prescicaoValidDateRules: (value) => this.testValue(value) || '',
        creditRecordExistsValue: true,
        creditRecordExistsDate: true,
        dateInvalid: (value) => this.testeDataIni(value) || 'Digite uma data válida',
      },
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
    }),
    creditToSave() {
      return this.creditoOrigin;
    },
  },

  created() {
    this.init();
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.form) {
        this.$refs.form.validate();
        clearInterval(interval);
      }
    }, 50);
    this.$refs.form.validate();
  },

  methods: {
    isValidValorOrNot(value) {
      if (value) {
        return value > '0,00';
      } else {
        return false;
      }
    },
    testeDataIni(value) {
      if (value) {
        let dataTested = moment(value, 'YYYY-MM-DD', true).isValid();
        return dataTested;
      } else {
        return false;
      }
    },
    init() {
      this.$on('open', this.open);
      this.$on('edit', this.edit);
      this.$on('close', this.close);
      this.getCompanies();
    },

    open() {
      this.title = 'Adicionar crédito';
      this.$emit('clean', '');
      this.show = true;
    },

    edit() {
      this.show = true;
      this.isEdit = true;
      this.title = 'Editar Crédito';
    },
    close() {
      this.show = false;
      this.isEdit = false;
      this.hasError = false;
      this.$refs.form.resetValidation();
      this.$emit('clean', '');
    },
    formatDate(date, format) {
      let otherFormat = format === 'YYYY-MM-DD' ? 'DD/MM/YYYY' : 'YYYY-MM-DD';
      if (!date) return null;
      if (moment(date, format, true).isValid()) {
        return date;
      }
      return moment(moment(date, otherFormat)).format(format);
    },

    salvar() {
      if (this.$refs.form.validate() === false) return;

      this.totalizadorSave = cloneDeep(this.creditToSave);

      if (this.creditToSave.id) {
        try {
          this.totalizadorSave.dataPrescricao = this.formatDate(this.creditToSave.dataPrescricao, 'DD/MM/YYYY');

          TotalizadorService.financial.update(this.totalizadorSave);
          this.hasError = false;
          this.close();
          this.showToast(`Alterações realizadas com sucesso!`, 'success', 78);
        } catch (error) {
          if (error.response.status === 409) {
            this.hasError = true;
            this.errorMessage = error.response.data.erros[0];
          }
        }
      } else {
        this.totalizadorSave.dataPrescricao = this.formatDate(this.creditToSave.dataPrescricao, 'DD/MM/YYYY');
        this.totalizadorSave.razaoSocial = this.creditToSave.empresa.socialName;
        this.totalizadorSave.cnpjRaiz = this.creditToSave.empresa.cnpj.substring(0, 8);
        delete this.totalizadorSave.empresa;

        try {
          this.creditoOrigin.dataPrescricao = this.totalizadorSave.dataPrescricao;
          this.creditToSave.dataPrescricao = this.totalizadorSave.dataPrescricao;
          TotalizadorService.financial.save(this.totalizadorSave);
          this.hasError = false;
          this.close();
          this.showToast(`Crédito adicionado com sucesso!`, 'success', 78);
        } catch (error) {
          if (error.response.status === 409) {
            this.hasError = true;
            this.errorMessage = error.response.data.erros[0];
          }
        }
      }
    },

    async getCompanies() {
      let { data } = await AdminServices.userEstablishment.get(this.usuario.id);

      let userEstablishments = data.map((item) => item.establishment);

      userEstablishments = sortBy(userEstablishments, ['socialName', 'stateCode', 'city']);
      let establishments = userEstablishments.filter(
        (v, i, a) => a.findIndex((t) => t.details.socialName === v.details.socialName) === i,
      );
      this.userEstablishment = establishments;
    },
    changeInput(creditToSave, nameField) {
      this.noValid = false;
      if (this.isEdit != true) this.creditRecordExists(creditToSave, nameField);
    },
    creditRecordExists(creditToSave, nameField) {
      if (nameField === 'valor' || nameField === 'empresa' || nameField === 'dataPrescricao') {
        let exists = this.totalizadores.some((tot) => {
          return (
            tot.razaoSocial === creditToSave.empresa.socialName &&
            tot.dataPrescricao === this.formatDate(creditToSave.dataPrescricao, 'DD/MM/YYYY') &&
            tot.valor === creditToSave.valor
          );
        });
        if (exists) {
          this.rules.creditRecordExistsValue =
            'Preencha um valor de crédito diferente. Já existe um registro de crédito identificado e com a mesma data de prescrição eminente.';
          this.rules.creditRecordExistsDate = 'Preencha uma data diferente';
        } else {
          this.rules.creditRecordExistsValue = true;
          this.rules.creditRecordExistsDate = true;
        }
      }
    },
    testValue(value) {
      let currentDate = moment().format('YYYY-MM-DD');
      if (moment(currentDate).isSameOrBefore(value)) {
        return true;
      } else {
        return 'Data deve ser igual ou maior que hoje.';
      }
    },

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
    validatesTotal(value) {
      if (
        !this.noValid &&
        this.creditToSave.expectativaTotalCredito &&
        this.creditToSave.expectativaTotalCredito <= value
      ) {
        this.messageEpectationGreaterThanValue = 'Valor da expectativa deve ser maior que o total';
        this.lowerExpectationMessage = 'Valor total deve ser menor que a expectativa';
      } else {
        this.lowerExpectationMessage = '';
        this.messageEpectationGreaterThanValue = '';
      }
    },

    validatesExpectation(value) {
      if (!this.noValid && this.creditToSave.expectativaTotalCredito && this.creditToSave.valor >= value) {
        this.messageEpectationGreaterThanValue = 'Valor da expectativa deve ser maior que o total';
        this.lowerExpectationMessage = 'Valor total deve ser menor que a expectativa';
      } else {
        this.messageEpectationGreaterThanValue = '';
        this.lowerExpectationMessage = '';
      }
    },
  },
};
</script>
